:root {
    --bs-primary: red !important;
}

.btn-language {
    position: relative;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: fit-content;
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    box-shadow: none;
}

.btn-language:hover {
    background-color: rgb(245, 245, 245);
}

.language .icon {
    margin-right: 15px;
}

.language .text {
    font-weight: 600;
    font-size: 16px;
    color: #333;
    margin-right: 45px;

}

.language .icon span {
    vertical-align: 0 !important;

}

.language .arrow {
    position: absolute;
    z-index: 1;
    right: 0px;
    display: flex;
    align-self: center;
    width: 14px;
    height: 12px;
    margin-right: 12px;
    top: 13px;

    background-repeat: no-repeat;
    transition: all 0.3s ease 0s;
}

.layout .left .companyInfo {
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
    padding-left: 13px;
    padding-right: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    align-content: space-around;
    color: #dedede;
    background-color: #273643;
}

.layout .left .companyInfo .avatar {
    width: 25%;
    border-radius: 50%;
}

.layout .left .companyInfo .avatar img {
    border-radius: 50%;
    width: 53px;
    border: 2px solid #dedede;
    background-color: #fff;
}

.layout .left .companyInfo .infos {
    width: 75%;
    padding-left: 15px;
    margin-top: 10px;
}

.layout .left .companyInfo .infos .nameSurname {
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.layout .left .companyInfo .infos .companyName {
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.layout a:link {
    text-decoration: none;
}

.layout .ant-menu-item-selected {
    background-color: #1677ff;
    border-radius: 0 !important;
}

.trigger {

    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}



svg {
    display: inline !important;
    vertical-align: baseline !important;
}

.dashboard .cardIcon {
    padding: 15px;
    width: fit-content;
    font-size: 22px;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.previewCompanyLogo {
    width: 150px;
}

.previewCompanyLogo img {
    width: 100%;
}




.steps .stepsLabel {

    padding: 12px 15px;
    color: rgba(26, 51, 83, .6);
    font-size: 12px;
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
    border-bottom: 1px solid #dedede;
    margin-bottom: 15px;
}

.steps ul {
    list-style-type: none;
    padding-left: 20px;

}

.steps ul li {

    position: relative;
    display: -webkit-flex;
    display: flex;
    height: 40px;
    -webkit-align-items: center;
    align-items: center;
    padding-left: 15px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}

.steps ul li.actives {
    color: #410099 !important;

    text-decoration: none;
    background-color: rgba(65, 0, 153, .1) !important;
    border-left: 3px solid #410099;
}

.steps ul li.completes {
    color: #333 !important;
    text-decoration: none;
}

.steps ul li.completes:after {
    content: "✓";
    position: absolute;
    right: 15px;
    color: #410099 !important;
}

.steps ul li.passive {
    color: #a1a1a1;
    text-decoration: line-through;
}

.checkboxPadding {
    padding: 5px;
}

.checkboxInput {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;
}

.colSetW {
    max-width: 100px;
}


.products {
    display: flex;
    align-items: center;
}

.products .img {
    padding-right: 15px;
    padding-left: 15px;
    border-right: 1px solid rgb(222, 222, 222);
    border-left: 1px solid rgb(222, 222, 222);
}

.products .img img {
    border-radius: 5px;
    width: 80px;
    height: 100%;
    object-fit: cover;
}

.products .prog {
    padding-left: 15px;
}

.products .detail {
    padding-left: 15px;

    margin-left: 15px;
}

.products .detail .categories {
    margin-bottom: 5px;
}

.products .detail .recordDate {
    font-size: 12px;
    color: rgb(153, 153, 153);
}

.ant-progress-circle:where(.css-dev-only-do-not-override-ixblex).ant-progress-status-success .ant-progress-text {
    color: #410099 !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-result-success .ant-result-icon>.anticon {
    color: #410099 !important;
}

.rbc-month-view {
    border: 1px solid #f2f2f2;
    border-radius: 7px;

}

.rbc-header+.rbc-header {
    border-left: 1px solid #f2f2f2 !important;
}

.rbc-header {
    padding: 10px 3px !important;
    border-bottom: 1px solid #f2f2f2 !important;
}

.rbc-month-row+.rbc-month-row {
    border-top: 1px solid #f2f2f2 !important;
}

.rbc-day-bg+.rbc-day-bg {
    border-left: 1px solid #f2f2f2 !important;
}

.rbc-off-range-bg {
    background: #e6e6e6;
}



.rbc-selected-cell {
    background-color: rgba(65, 0, 153, .15) !important;
}

.rbc-today {
    background-color: rgba(65, 0, 153, .05) !important;
}

.rbc-date-cell {
    border-bottom: 1px solid #f2f2f2;
    font-weight: bold !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .035) !important;
    padding: 0 !important;
    /* 
   background-color: rgba(7, 1, 63, 0.527);
   color:#f2f2f2
   */
}

.rbc-date-cell .rbc-button-link {
    font-weight: bold !important;
}

.rbc-day-bg:not(.rbc-off-range-bg) {
    cursor: pointer;
}

.rbc-day-bg:not(.rbc-off-range-bg):hover {

    background-color: rgba(65, 0, 153, .15) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.eventContainer {
    background-color: transparent !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    border-bottom: 1px solid #f2f2f2 !important;
}

.rbc-event:focus {
    outline: none !important;
}

.eventContainer .ant-tag:hover {
    border: 1px solid #333;
    background-color: #410099;
    color: #fff;
}

.bg-custom {
    background-color: #410099;
}

.rbc-header .badge {
    cursor: pointer;
}

.rbc-header .badge:hover {
    opacity: 0.7;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.rbc-off-range {
    color: #e1e1e1;
}

.rbc-month-view {
    padding: 5px;
}


.b-example-divider {
    width: 100%;
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
    flex-shrink: 0;
    width: 24px;
    height: calc(100vh - 60px);
}

.scrollarea {
    overflow-y: auto;
}


.zone {
    position: relative;
    border-radius: 7px;
    width: 100%;
    border: 3px dashed #999;
    margin-bottom: 15px;
    padding: 0;
}


.deskMain1Pax {

    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
}

.chair {
    width: 21px;
    height: 21px;
    line-height: 21px;
    font-size: 14px;
    margin: 4px;

    border-radius: 6px;
    color: #fff;
    list-style: none;
    display: inline-block;

    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    cursor: pointer;
}

.deskMain2Pax {
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.10);
    cursor: pointer;
    position: absolute;
    padding: 5px;
}


.deskMain2PaxH {
    width: 128px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.10);
    cursor: pointer;
    position: absolute;
}




.desk {
    display: flex;
    height: 58px;
    width: 58px;
    margin: 4px;
    transform: translate(0px, 0px);
    border-radius: 6px;
    color: #fff;
    list-style: none;
    position: relative;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}



.deskH {
    display: flex;
    height: 72px;
    width: 48px;
    margin: 4px;
    transform: translate(0px, 0px);
    border-radius: 6px;
    color: #fff;
    list-style: none;
    position: relative;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}


.deskMain3Pax {
    display: flex;
    width: 158px;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.10);
    cursor: pointer;
    position: absolute;
}

.deskMain7Pax {
    display: flex;
    width: 192px;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.10);
    cursor: pointer;
    position: absolute;
}

.middleDesk {
    align-items: center;
    display: flex;
}

.badges {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    color: #fff;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #fff;
    bottom: -15px;
    left: 50%;
    transform: translate(-50%, 0);
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    -webkit-touch-callout: none;
    text-transform: uppercase;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}

.remove {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #333;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    top: 0px;
    right: 0px;
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .45);

}


.edit {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #87d068;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    top: 0px;
    left: 0px;
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .45);

}


.rotate {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #2db7f5;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    bottom: 0px;
    right: 0px;
    padding-left: 3px;
    padding-right: 3px;
    position: absolute;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .45);

}

.cursor {
    cursor: pointer;
}

/**/

.deskContainer {


    display: flex;
    align-items: center;
    border-radius: 6px;
    background: #dfe0e1bf;
    cursor: pointer;
    position: absolute;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .15);
    flex-direction: column;
    padding: 10px;
    justify-content: space-evenly;
}

.person {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    margin: 4px;

    border-radius: 6px;
    color: #fff;
    list-style: none;
    display: inline-block;

    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    cursor: pointer;
}

.squareDeskTopBottom {
    display: flex;
    width: calc(100% - 64px);
    justify-content: center;
}


.squareDeskLeftRight {
    display: flex;
    flex-direction: column;
    width: 32px;
    justify-content: center;
    align-items: center;
}




.squareDesk {

    display: flex;
    height: 100%;
    width: 100%;
    padding: 15px;
    transform: translate(0px, 0px);
    border-radius: 6px;
    color: #fff;
    list-style: none;
    position: relative;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.scan {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.scan .qrcode {

    position: relative;

    width: 400px;

    height: 400px;

    background: url(../img/qr_code1.png);

    background-size: 400px;

}

.scan .qrcode::before {

    content: '';

    position: absolute;

    top: 0;

    left: 0;

    width: 100%;

    background: url(../img/qr_code2.png);

    background-size: 400px;

    overflow: hidden;

    animation: animate 4s ease-in-out infinite;

}

@keyframes animate {

    0%,
    100% {

        height: 20px;

    }

    50% {

        height: calc(100% - 20px);

    }

}

.scan .qrcode::after {

    content: '';

    position: absolute;

    inset: 20px;

    width: calc(100% - 40px);

    height: 2px;

    background: #35fd5c;

    filter: drop-shadow(0 0 20px #35fd5c) drop-shadow(0 0 60px #35fd5c);

    animation: animateLine 4s ease-in-out infinite;

}

@keyframes animateLine {

    0% {

        top: 20px;

    }

    50% {

        top: calc(100% - 20px);

    }

}

.scan h3 {

    color: #fff;

    letter-spacing: 2px;

    margin-top: 20px;

    text-transform: uppercase;

    font-size: 2em;

    filter: drop-shadow(0 0 20px #fff) drop-shadow(0 0 60px #fff);

    animation: animateText 0.5s steps(1) infinite;

}

@keyframes animateText {

    0%,
    100% {

        opacity: 0;

    }

    50% {

        opacity: 1;

    }

}

.border {

    position: absolute;
    inset: 0;
    background: url(../img/border.png);
    background-size: 400px;
    background-repeat: no-repeat;
    animation: animateText 0.5s linear infinite;
    border: 0 !important;

}


.scan .qrcodeScanBefore {

    position: relative;
    width: 400px;
    height: 400px;
    background-size: 400px;
}

.waitingScan {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

}



.borderScanBefore {

    position: absolute;
    inset: 0;
    background: url(../img/border.png);
    background-size: 400px;
    background-repeat: no-repeat;

    border: 0 !important;

}

.terminalInput {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 0;
    color: white;
    text-align: center;
    font-size: 42px;
}

.terminalInput:focus {
    outline: none;
    border-color: transparent;
    border: 0;
    box-shadow: 0 0 3px transparent;
    -moz-box-shadow: 0 0 3px transparent;
    -webkit-box-shadow: 0 0 3px transparent;
    text-shadow: 0 0 0 #fff;
    color: transparent !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
}

.ticket {
    padding: 30px;
    max-width: 640px;
    width: 100%;
    position: absolute;
    background-color: #000;
    color:white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0 0 20px #fff) drop-shadow(0 0 60px #fff);

}

.information {
    margin-left: 30px;
    margin-right: 30px;
}

.information .text {
    margin-bottom: 30px;
}

.information .text .title-container {
    display: flex;
    font-style: italic;
    font-size: 12px;
}

.information .text .sub-title-container {
    display: flex;
    font-size: 12px;
    margin-top: 15px;

}

.information .text .title-container>div {
    margin-right: 15px;
    font-weight: bold;
    color: #808285;

}


.information .text .sub-title-container>div {

    font-weight: bold;
    color: #808285;

}


.information .text .sub-title-container>div:first-child {
    width: 390px;
}

.information .text .sub-title-container .seperator {}


.information .text .desc-container {
    font-size: 21px;
    font-weight: bold;
}



.information .text .sub-desc-container {
    font-size: 21px;
    font-weight: bold;
    display: flex;
}

.information .text .sub-desc-container {
    font-size: 21px;
    font-weight: bold;
    display: flex;
}


.information .text .sub-desc-container>div:first-child {

    width: 390px;


}

.showHidePassword {
    position: absolute;
    right: 15px;
    top:18px
}


:root {
    --bs-primary: red !important;
}



.menu-container {
    width: 100%;
    position: relative;
}

.menu-container .menu-Button {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.menu-container .menu-Button .menuTitle {
    color: #000;
    font-weight: bold;
    font-size: 1.6rem;
}

.menu-container .menu-Button .chevron {
    margin-left: 5px;
    height: 10px;
}

.menu-container .menu-Button .chevron>img {
    height: 10px;
}

.menu-container .menu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 40px;
    left: -20%;
    width: 430px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 99;
}

.menu-container .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.menu-container .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-container .menu li {
    border-bottom: 1px solid #dddddd;
    padding: 15px 20px;
}

.menu-container .menu li:last-child {
    border: 0;
}

.menu-container .menu li>div {
    text-decoration: none;
    display: block;
    color: #000;
    font-weight: bold;
    font-size: 1.2rem;
}


.autocompleteContainer li:first-child {
    border-top: 0;
    border-radius: 0;

}

.autocompleteContainer li:last-child {
    border-bottom: 0;
    border-radius: 0;
}

.autocompleteContainer li {
    border-left: 0;
    border-right: 0;
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.autocompleteContainer li span::before {
    content: '◉';
    margin-right: 5px;

}

.autocompleteContainer li .noResult {
    content: '';
    color: #ff0000;
    margin-right: 5px;

}

.autocompleteContainer li .noResult::before {

    content: '';
    color: #ff0000;
    margin-right: 5px;

}

.autocompleteContainer li:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}


.react-tel-input .special-label {
    left: 15px;

}

.react-tel-input .form-control {
    width: 100% !important;
    padding: 16px 14px 16px 58px !important;
}

.react-tel-input .form-control:focus {
    outline: none !important;
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.react-tel-input .form-control:focus.phone-is-invalid {

    color: var(--bs-danger);
    border-color: var(--bs-danger) !important;
    box-shadow: 0 0 0 0.25rem rgba(253, 13, 13, 0.25);
}



.float-label {
    position: relative;

}

.label {
    font-size: 13px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 15px;
    transition: 0.2s ease all;
    font-size: 16px;
}

.label-float {
    background: white;
    width: auto;
    top: -10px;
    padding: 0 6px;
    font-size: 13px;
}

.ant-input {
    padding: 12px 12px 8px 11px;
}

.ant-select .ant-select-selector {
    padding: 16px 13px 4px 12px;
    min-height: 58px;
}

/*.ant-select-focused {*/
.ant-select-focused {
    border-radius: 7px;
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.tree-is-invalid .ant-select-focused {
    border-radius: 7px;
    color: var(--bs-body-color);
    background-color: var(--bs-form-control-bg);
    border-color: #ff0000;
    box-shadow: 0 0 0 0.25rem rgba(253, 13, 13, 0.25);
}

.tree-is-invalid .ant-select-selector {
    border-color: #ff0000 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 16px 10px 4px 11px;
    min-height: 58px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 16px;
}


.tree-is-invalid .ant-select {
    border-color: var(--bs-danger);
}


.tree-is-invalid .ant-select .ant-select-selector {
    border-color: var(--bs-danger);
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    transition: none
}


/*
Popup
*/
.modals {
    position: fixed;

    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    z-index: 901;
}


.backdrop {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.65);
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    z-index: 900;
}


.modals .modals-container {
    width: 50%;


    position: absolute;
    z-index: 902;
    min-height: 20vh;
    max-height: 80vh;
    background-color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

.showHidePassword {
    position: absolute;
    top: 14px;
    right: 15px;
    cursor: pointer;
}

.file-upload {
    height: 50px;
    width: 100%;
    border-radius: 7px;
    border: 3px dashed #dedede;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    background-color: #fff;
    color: #666;
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
    margin-right: 15px;
}

.file-upload input[type=file] {

    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
}


.previewLink {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: inherit;
}

.uploadButtonText {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 0 !important;
    margin-left: 4px !important;
}

.loadingContent {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 7px;
    z-index: 99999;
}

.loadingContent .loadIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}

.btn-primary {
    background-color: #41009a !important;
    border-color: #41009a !important;
}

.btn-outline-primary {
    --bs-btn-color: #41009a;
    --bs-btn-border-color: #41009a;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #41009a;
    --bs-btn-hover-border-color: #41009a;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41009a;
    --bs-btn-active-border-color: #41009a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #41009a;
    --bs-gradient: none;
}


.form-check-input:checked {
    background-color: #41009a !important;
    border-color: #41009a !important;
}

.bigCheckbox {
    transform: scale(2);
    margin-top: 10px;
    margin-right: 30px;
}


.inputButtonSet {
    display: flex;
    position: absolute;
    right: 0;
    height: 58px;
    top: 0;

    align-items: center;


}

.inputButtonSet>div {
    padding-left: 15px;
    padding-right: 15px;
    height: 58px;
    align-items: center;
    display: flex;
    background-color: #40009a15;
    cursor: pointer;
}

.inputButtonSet>div:hover {
    background-color: #40009a41;
}

.inputButtonSet>div:last-child {
    border-left: 1px solid #dedede;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
}

.ant-switch-inner {
    line-height: 21px !important;
}

.ItineraryStep {
    position: relative;
    border: 2px dashed #dedede;
    padding: 20px 20px 20px 20px;
    background-color: #0505050f;
    border-radius: 7px;
}

.limitText {
    overflow: hidden;

    text-overflow: ellipsis;
    display: -webkit-box;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


.timeline {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.timeline .list-group-item+.list-group-item {
    border-top-width: 1px !important;
}

.timeline .listing {
    position: relative;
    margin-left: 20px;
    border: 1px solid #f2f2f2;
    background-color: #f9f9f9;
    border-radius: 7px;
    padding: 15px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);
}

.timeline .listing:before {
    content: '';
    background-color: rgba(65, 0, 153, .5);
    ;
    position: absolute;
    bottom: -10px;
    top: -8px;
    left: -21px;
    width: 3px;
}

.timeline .listing:first-child::before {
    top: 50%;
}

.timeline .listing:last-child::before {
    bottom: 50%;
}

.timeline .listing:after {
    content: '◉';
    color: #41009a;
    border-radius: 50% 50% 50% 50%;
    border: 1px solid rgba(65, 0, 153, .5);
    background-color: rgba(65, 0, 153, .1) !important;
    position: absolute;
    left: -34px;
    top: 2px;
    height: 28px;
    width: 28px;
    top: 50%;
    transform: translate(0%, -50%);
    text-align: center;
    font-weight: bold;
    padding-top: 2px;
}



.itineraryCardHeader {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
}

.itineraryBorderBottom {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 15px;
    margin-bottom: 15px;
}


.itineraryCardBody {
    padding: 20px
}

.maps {
    width: 100%;
    height: 400px;
    margin-top: 15px;
    border-radius: 7px;
}

.mapButtonSet {
    position: absolute;
    width: 50%;
    bottom: 0px;
    left: 25%;
}



.mapButtonSet .buttonContainer {
    border-radius: 7px 7px 0px 0px;
    position: relative;
    width: fit-content;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
    box-shadow: 1px 0px 2px 0 rgb(0 0 0 / 3%), 0 -1px 6px -1px rgb(0 0 0 / 2%), 0 -2px 4px 0 rgb(0 0 0 / 2%);
}


.mediaButtonSet {
    display: flex;
}

.mediaButtonSet>div {
    display: flex;
    height: 90px;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    color: white
}

.mediaButtonSet .delete {
    background-color: #ff000080;
}

.mediaButtonSet .move {
    background-color: #40009a41;
    border-left: 1px solid #dedede;
    border-radius: 0px 7px 7px 0px;
}


.criteria {
    border: 2px dashed #dedede;
    padding: 20px 20px 20px 20px;
    background-color: #0505050f;
    border-radius: 7px;
}

.criteriaButtonSet {
    justify-content: flex-end;
    display: flex;
}

.criteriaButtonSet>div {
    display: flex;
    height: 30px;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    color: white
}

.criteriaButtonSet .delete {
    background-color: #ff000080;
}

.criteriaButtonSet .move {
    background-color: #40009a41;
    border-left: 1px solid #dedede;
    border-radius: 0px 7px 0px 0px;
}

.InformationIcon {
    position: absolute;
    top: -7px;
    right: -5px;
    border: 1px solid;
    border-radius: 50%;
    padding-left: 4px;
    padding-right: 4px;
    /* background-color: gray; */
    background-color: #e6f4ff;
    border: 1px solid #91caff;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;

}

.InformationIconRelative {
    border: 1px solid;
    border-radius: 50%;
    padding-left: 4px;
    padding-right: 4px;
    /* background-color: gray; */
    background-color: #e6f4ff;
    border: 1px solid #91caff;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
}

.text-bg-primary {
    background-color: #41009a !important;
}

.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}


.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}




